import { useFetchData } from "@custom-hooks/useFetchData";
import { FetchHookResult } from "@data-types/generic-hook-type";
import { Regions } from "@data-types/regions-types";

/**
 * Custom hook to fetch all of the regions.
 *
 * @returns {FetchHookResult<Regions>} The result object with the list of regions
 *   (or `undefined` if no regions are available), UI state indicators, and a function to manage fetched data.
 */
export function useGetRegions(): FetchHookResult<Regions> {
  return useFetchData<Regions>(
    true,
    `/api/regions`,
    "useGetRegions",
    "backend",
    {
      revalidateOnFocus: false,
    }
  );
}
