//
// useGetAvailableRegions.ts
//

import { useFetchData } from "@custom-hooks/useFetchData";
import { FetchHookResult } from "@data-types/generic-hook-type";
import { Regions } from "@data-types/regions-types";

/**
 * Custom hook to fetch and monitor the availability of regions from the backend.
 *
 * @param {number} [refreshInterval] - Optional interval (in milliseconds) for refreshing project info data.
 *
 * @returns {FetchHookResult<Regions>} The result object with the list of regions
 *   (or `undefined` if no regions are available), UI state indicators, and a function to manage fetched data.
 */
export function useGetAvailableRegions(
  refreshInterval?: number
): FetchHookResult<Regions> {
  return useFetchData<Regions>(
    true,
    `/api/regions`,
    "useGetAvailableRegions",
    "backend",
    {
      refreshInterval,
      revalidateOnFocus: false,
    }
  );
}
