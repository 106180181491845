import { useEditData_v2 } from "@custom-hooks/edit-data";
import { EditDataOpt } from "@data-types/generic-hook-type";
import { Project } from "@data-types/projects-types";
import generator from "generate-password";

/**
 * Custom hook for handling free project creation logic.
 *
 * @param onSuccess - Callback to execute upon successful project creation.
 * @param onError - Callback to execute upon error during project creation.
 *
 * @returns Hook state and actions for managing project creation.
 */
export function useCreateFreeProject(options: EditDataOpt<Project>) {
  const { isMutatingData, isLoading, editData } = useEditData_v2({
    ...options,
    stopGlobalError: options?.stopGlobalError ?? true,
  });

  const createFreeProject = async ({
    projectName,
    selectedRegion,
    selectedLabel,
  }: {
    projectName: string;
    selectedRegion: string;
    selectedLabel: number;
  }) => {
    await editData({
      method: "POST",
      endpoint: `/api/projects/create`,
      endpointCallLocation: "useCreateFreeProject",
      body: {
        name: projectName,
        region: selectedRegion,
        env: selectedLabel,
        plan_id: "sandbox",
        admin_username: "adminName", // TODO:DAMLA - remove
        admin_password: generator.generate({
          length: 10,
          numbers: true,
          excludeSimilarCharacters: true,
        }),
        description: "My awesome project",
      },
      mutateApis: [
        ["/api/projects", "useGetUserProjects"],
        ["/api/projects", "useGetProjects"],
      ],
    });
  };

  return {
    createFreeProject,
    isCreatingProject: isLoading || isMutatingData,
  };
}
