//
// CreateProjectDialog.tsx
//

import { plans, PRO_PROJECT_PRICE } from "@app/api/billing/lib/constants";
import { useGetUserPlan, useUpdateSubscription } from "@custom-hooks/billing";
import { getEnvironmentOptions } from "@custom-hooks/projects";
import { useCreateFreeProject } from "@custom-hooks/projects/hooks/useCreateFreeProject";
import { useGetRegions } from "@custom-hooks/regions";
import {
  AddonActionNames,
  AddonLookupKey,
  PlanFunctionality,
  PlanLookupKey,
} from "@data-types/billing-types";
import { Region, Regions } from "@data-types/regions-types";
import { validateString } from "@lib/iso-utils";
import { ContactDialog } from "@tw-components/OrgHome/ContactDialog";
import { Alerts, MessageType } from "@tw-components/ui/alerts";
import { GenericDialog } from "@tw-components/ui/dialog";
import { GenericInput } from "@tw-components/ui/input";
import {
  GenericListbox,
  ListboxLabel,
  ListboxOption,
} from "@tw-components/ui/listbox";
import { useEffect, useState } from "react";

const UPGRADING_INFRA = process.env.NEXT_PUBLIC_UPGRADING_INFRA;

/**
 * The props for the CreateProjectDialog component.
 *
 * @property {boolean} isOpen - Indicates whether the dialog is open.
 * @property {() => void} onClose - Callback function to close the dialog.
 */
type CreateProjectDialogProps = {
  isOpen: boolean;
  onClose: () => void;
};

/**
 * CreateProjectDialog component that displays a dialog for creating a new project.
 *
 * @param {CreateProjectDialogProps} props - The props for the CreateProjectDialog component.
 *
 * @returns {JSX.Element} The rendered `CreateProjectDialog` component.
 */
export function CreateProjectDialog({
  isOpen,
  onClose,
}: CreateProjectDialogProps): JSX.Element {
  const { updateSubscription, isLoading: isLoadingUpdateSubscription } =
    useUpdateSubscription({
      successCallback: (data) => {
        if (data?.value.status === "pending") {
          setInvoiceHostedUrl(data?.value.redirectUrl);
          setFurtherActionRequired(true);
        } else if (data?.value.status === "completed") {
          onClose();
        }
      },
      errorCallback: () => {
        // TODO:DAMLA - show alert inside
      },
    });
  const { isCreatingProject, createFreeProject } = useCreateFreeProject({
    mutatedCallback: onClose,
    errorCallback: () => {
      console.warn("damla - project creation failed");
    }, // TODO:DAMLA - show alert inside
  });

  const plan = useGetUserPlan();
  const {
    data: regions,
    showLoader: showLoaderRegions,
    isError: isErrorRegions,
  } = useGetRegions();
  const environmentOptions = getEnvironmentOptions();

  const [regionOptions, setRegionOptions] = useState<Regions>([]);

  const [projectName, setProjectName] = useState<string>("");
  const [selectedRegion, setSelectedRegion] = useState<
    Region["region_id"] | undefined
  >(undefined);
  const [selectedLabel, setSelectedLabel] = useState<number>(
    environmentOptions[0].value
  );
  const [errors, setErrors] = useState<Record<string, string | undefined>>({});
  const [showContactDialog, setShowContactDialog] = useState(false);

  const planDetails =
    plan === PlanLookupKey.PRO_PLAN_MONTHLY
      ? plans[PlanLookupKey.PRO_PLAN_MONTHLY].functionalityDetails
      : plans[PlanLookupKey.SANDBOX].functionalityDetails;

  const details =
    plan === undefined
      ? []
      : [
          {
            name: PlanFunctionality.CLUSTER,
            value: planDetails[PlanFunctionality.CLUSTER]?.summary,
          },
          {
            name: PlanFunctionality.STORAGE_IN_GB,
            value: planDetails[PlanFunctionality.STORAGE_IN_GB]?.summary,
          },
          {
            name: `${PlanFunctionality.VCPU} / ${PlanFunctionality.RAM}`,
            value: `${planDetails[PlanFunctionality.VCPU]?.summary} / ${planDetails[PlanFunctionality.RAM]?.summary}`,
          },
          {
            name: PlanFunctionality.RETENTION_IN_HOURS,
            value: planDetails[PlanFunctionality.RETENTION_IN_HOURS]?.summary,
          },
          {
            name: PlanFunctionality.SUPPORT,
            value: planDetails[PlanFunctionality.SUPPORT]?.summary,
          },
        ];

  const handleCreateProject = async () => {
    if (UPGRADING_INFRA && UPGRADING_INFRA.toLowerCase() === "true") {
      alert(
        "We're upgrading our infrastructure to serve you better! Creating a new project is temporarily disabled."
      );

      return;
    }

    if (selectedRegion === undefined || projectName === "") {
      return;
    }

    switch (plan) {
      case PlanLookupKey.SANDBOX:
        await createFreeProject({
          projectName,
          selectedRegion,
          selectedLabel,
        });
        break;
      case PlanLookupKey.PRO_PLAN_MONTHLY:
        await updateSubscription({
          newAddons: [
            {
              addon: AddonLookupKey.PRO_PROJECT_MONTHLY,
              quantity: 1,
              action: [
                {
                  name: AddonActionNames.CREATE,
                  data: {
                    name: projectName,
                    region: selectedRegion,
                    env: selectedLabel,
                  },
                },
              ],
            },
          ],
        });
        break;
      default:
        // TODO:DAMLA
        break;
    }
  };

  useEffect(() => {
    if (regions) {
      setRegionOptions(regions);
      setSelectedRegion(
        process.env.NEXT_PUBLIC_USE_DEFAULT_REGION_K8S as Region["region_id"]
      );
    }

    if (isErrorRegions) {
      setErrors({
        ...errors,
        region: "Network error: Unable to fetch regions.",
      });
    }
  }, [regions, isErrorRegions]);

  useEffect(() => {
    if (projectName != "") {
      const { valid, messages } = validateString(projectName);

      setErrors({ projectName: valid ? undefined : messages[0] });
    }
  }, [projectName]);

  const [furtherActionRequired, setFurtherActionRequired] = useState(false);
  const [invoiceHostedUrl, setInvoiceHostedUrl] = useState("");

  const redirectToStripe = () => {
    window.open(invoiceHostedUrl, "_blank");
    onClose();
  };

  return (
    <>
      <GenericDialog
        title="Create a new project"
        body={
          <div className="tw-flex tw-flex-col tw-gap-4">
            <div className="tw-text-16px-semiBold tw-text-text-title-light dark:tw-text-text-title-dark">
              Configuration
            </div>

            <GenericInput
              sizeVar="small"
              label="Project name"
              placeholder="my-project"
              name="projectName"
              value={projectName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setProjectName(e.target.value);
              }}
              errors={errors}
              disabled={
                isLoadingUpdateSubscription ||
                isCreatingProject ||
                furtherActionRequired
              }
            />

            <div className="tw-grid tw-grid-cols-1 tw-gap-4 sm:tw-grid-cols-2">
              <GenericListbox
                style="default"
                size="small"
                label="Region"
                placeholder="Loading regions..."
                name="region"
                value={isErrorRegions ? "" : selectedRegion}
                onChange={(value: Region["region_id"]) =>
                  setSelectedRegion(value)
                }
                disabled={
                  showLoaderRegions ||
                  isErrorRegions ||
                  isLoadingUpdateSubscription ||
                  isCreatingProject ||
                  furtherActionRequired
                }
                showLoader={showLoaderRegions}
                errors={errors}
              >
                {regionOptions.map((region) => (
                  <ListboxOption
                    value={region.region_id}
                    key={region.region_id}
                  >
                    <ListboxLabel>{region.description}</ListboxLabel>
                  </ListboxOption>
                ))}
              </GenericListbox>

              <GenericListbox
                style="default"
                size="small"
                label="Label"
                value={selectedLabel}
                onChange={(value) => setSelectedLabel(value)}
                disabled={
                  isLoadingUpdateSubscription ||
                  isCreatingProject ||
                  furtherActionRequired
                }
              >
                {environmentOptions.map((environment) => (
                  <ListboxOption
                    value={environment.value}
                    key={environment.value}
                  >
                    <ListboxLabel>{environment.label}</ListboxLabel>
                  </ListboxOption>
                ))}
              </GenericListbox>
            </div>

            <div className="tw-flex tw-border-t tw-border-lightestGrey" />

            <div className="tw-text-16px-semiBold tw-text-text-title-light dark:tw-text-text-title-dark">
              Details
            </div>

            <div className="tw-flex tw-flex-col tw-gap-2">
              {details.map((detail) => (
                <div
                  className="tw-flex tw-flex-row tw-justify-between tw-gap-2 tw-py-1.5"
                  key={detail.name}
                >
                  <div className="tw-text-14px-regular tw-text-mediumblue">
                    {detail.name}
                  </div>

                  <div className="tw-text-14px-regular tw-text-mediumblue">
                    {detail.value ?? "None"}
                  </div>
                </div>
              ))}
            </div>

            <div className="tw-flex tw-border-t tw-border-lightestGrey" />

            <div className="tw-flex tw-flex-row tw-justify-between tw-gap-2 tw-py-1.5">
              <div className="tw-text-16px-semiBold tw-text-text-title-light dark:tw-text-text-title-dark">
                Cost
              </div>

              <div className="tw-text-16px-semiBold tw-text-text-title-light dark:tw-text-text-title-dark">
                {(() => {
                  switch (plan) {
                    case PlanLookupKey.SANDBOX:
                      return "Free";
                    case PlanLookupKey.PRO_PLAN_MONTHLY:
                      return `$${PRO_PROJECT_PRICE} / mo`;
                    default:
                      return "";
                  }
                })()}
              </div>
            </div>

            {furtherActionRequired && (
              <Alerts
                messages={[
                  {
                    type: MessageType.Error,
                    title: "Further action is required on Stripe",
                  },
                ]}
              />
            )}
          </div>
        }
        secondaryButtonProps={{
          text: "Need more? Contact us",
          onClick: () => {
            onClose();
            setShowContactDialog(true);
          },
        }}
        submitButtonProps={{
          variant: "primary",
          size: "medium",
          label: furtherActionRequired ? "Continue to Stripe" : "Create",
          onClick: furtherActionRequired
            ? redirectToStripe
            : handleCreateProject,
          className: "tw-w-[6.625rem]",
          disabled:
            selectedRegion === undefined ||
            projectName === "" ||
            Object.values(errors).some((error) => error !== undefined),
          showLoader: isLoadingUpdateSubscription || isCreatingProject,
        }}
        onClose={onClose}
        open={isOpen}
        size="lg"
        buttonLayoutType="column"
      />

      <ContactDialog
        isOpen={showContactDialog}
        onClose={() => setShowContactDialog(false)}
      />
    </>
  );
}
